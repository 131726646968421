<template>
    <div class="file-input-component-wrap no-style">
        <div class="upload-photo">
            <b-overlay :show="loading" rounded="sm" class="loading">
                <b-avatar variant="primary" class="add-photo-img">
                    <BootstrapIcon
                        v-if="!mutablePreviewImage.length"
                        icon="camera"
                        size="1x"
                        class="upload-placeholder"
                    />
                    <img
                        v-if="mutablePreviewImage.length"
                        class="file-input-component-image-preview w-full h-auto"
                        :src="mutablePreviewImage"
                        alt="image-preview"
                    />
                </b-avatar>
            </b-overlay>
            <div v-if="!forUserSettings" class="edit-badge" @click="triggerInput">
                <BootstrapIcon
                    icon="pencil"
                    size="1x"
                />
            </div>
        </div>

        <div v-if="!forUserSettings" class="file-input-component hidden">
            <input
                ref="fileInput"
                class="file-input"
                type="file"
                :accept="accept"
                @input="pickFile"
            />
        </div>
    </div>
</template>

<script>
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
    name: 'ImageUpload',
    props: {
        previewImage: {type: String, default: null},
        accept: {type: String, default: 'image/*'},
        forUserSettings: {type: Boolean, default: false},
    },
    mixins: [ResourceUtils],
    data() {
        return {
            mutablePreviewImage: [],
            loading: false,
        }
    },
    created() {
        if (this.previewImage && this.accept === 'image/*') {
            this.mutablePreviewImage.push(this.previewImage)
        }
    },
    methods: {
        triggerInput() {
            this.$refs.fileInput.click()
        },
        pickFile() {
            const input = this.$refs.fileInput
            const file = input.files
            const promises = []

            if (file && file[0]) {
                this.loading = true
                for (let i = 0; i < file.length; i += 1) {
                    const reader = new FileReader()
                    reader.onload = e => {
                        this.mutablePreviewImage = []
                        if (this.accept === 'image/*') {
                            this.mutablePreviewImage.push(e.target.result)
                        }
                    }
                    reader.readAsDataURL(file[i])
                    this.$emit('input', file[i])
                    promises.push(this.fileUpload(file[i]))
                }
                Promise.all(promises).then(results => {
                    if (results.length) {
                        this.loading = false
                    }
                })
            }
        },
        fileUpload(file) {
            const formdata = new FormData()
            formdata.append('file', file)
            return this.create(this.$Files, formdata, this.$t("messages.fileCreated"), null, this.success, null)
        },
        success(response) {
            this.$emit('fileUploaded', response.data)
        }
    },
}
</script>
