<template>
    <div class="content-box">
        <image-upload :preview-image="fileURL" @fileUploaded="setPhoto"/>
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols="12">
                    <b-form-group
                        :class="{ row, error: v$.user.givenName.$errors.length }"
                        :label="$t('forms.givenName')"
                        label-for="h-first-name"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input v-model="user.givenName" id="h-first-name"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.user.givenName.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.user.familyName.$errors.length }"
                        :label="$t('forms.familyName')"
                        label-for="h-last-name"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input v-model="user.familyName" id="h-last-name"/>
                        <div
                            class="input-errors"
                            v-for="error of v$.user.familyName.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.telephone')"
                        label-for="h-phone"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input id="h-phone" v-model="user.privateTelephone"/>
                    </b-form-group>
                    <b-form-group
                        class="row"
                        :label="$t('forms.station')"
                        label-for="h-station"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <v-select
                            id="h-station"
                            v-model="stationSelect"
                            :options="stations"
                        />
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.user.privateEmail.$errors.length }"
                        :label="$t('forms.privateEmail')"
                        label-for="h-email"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input id="h-email" type="email" v-model="user.privateEmail"/>

                        <div
                            class="input-errors"
                            v-for="error of v$.user.privateEmail.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        class="row"
                        :label="$t('forms.password')"
                        label-for="h-password"
                        label-cols-md="2"
                        content-cols-lg="4"
                    >
                        <b-form-input
                            id="h-password"
                            type="password"
                            v-model="user.plainPassword"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {required, email} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ImageUpload from "@/components/Inputs/ImageUpload.vue";
import ListUtils from "@/mixins/ListUtils";
import vSelect from "vue-select";

export default {
    components: {
        ImageUpload,
        vSelect
    },
    props: {
        userObject: {Object, required: true},
        action: {type: String, default: null},
    },
    mixins: [ResourceUtils, ListUtils],
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            user: {
                givenName: {required},
                familyName: {required},
                privateEmail: {email},
            },
        };
    },
    data() {
        return {
            user: this.userObject,
            fileURL: null,
            stationSelect: {},
            stations: []
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    created() {
        if (this.userObject.fileUrl) {
            this.fileURL = this.userObject.fileUrl;
        }
        if (this.user.station) {
            this.stationSelect = {
                label: this.user.station.name,
                id: this.user.station['@id'],
            };
        }
        this.getResourceList(this.$Stations, this.stations)
    },
    methods: {
        setPhoto(data) {
            if ('file' in this.user) {
                this.deleteByUrl(this.$Files, this.user.file, null);
            }
            this.user.file = data["@id"];
            this.fileURL = data.url;
            const body = {
                file: this.user.file
            }
            this.update(this.$Users, this.user.id, body, this.$t('messages.userUpdated'));
        },
        async onSubmit() {
            const isValid = await this.v$.$validate();
            const body = {
                familyName: this.user.familyName,
                givenName: this.user.givenName,
                privateEmail: this.user.privateEmail,
                privateTelephone: this.user.privateTelephone,
            }
            if (this.stationSelect) {
                body.station = this.stationSelect.id
            }
            if (this.user.plainPassword) {
                body.plainPassword = this.user.plainPassword
            }
            if (isValid && this.action === "update") {
                this.$emit("clearAction");
                this.update(this.$Users, this.user.id, body, this.$t("messages.userUpdated"), null, this.success, this.error);
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            localStorage.setItem('userData', JSON.stringify(this.user))
        },
        error() {
            this.$emit("clearAction");
        },
    },
};

</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
